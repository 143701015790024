<template>
  <div align="center">
    <h1>{{ $t('playlist_player.title') }}</h1>
    <v-card
      width="80%"
      v-for="(playlist,i) in playlists" :key="i"
      class="mt-2"
      flat
    >
      <v-toolbar dense>
        <v-toolbar-title> {{playlist.name}} </v-toolbar-title>
        <v-col class="ml-auto" align="right">
          <v-btn :disabled="isPlayOn(playlist) || !isOperator" color="primary--text" elevation="0" @click="play(playlist, 'Playlist')"><v-icon>mdi-play</v-icon></v-btn>
          <v-btn :disabled="isStoped(playlist) || !isOperator" color="primary--text" elevation="0" @click="pause(playlist, 'Playlist')"><v-icon>mdi-pause</v-icon></v-btn>
          <v-btn :disabled="isStoped(playlist) || !isOperator" color="primary--text" elevation="0" @click="stop(playlist, 'Playlist')"><v-icon>mdi-stop</v-icon></v-btn>
        </v-col>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script>
import Api from "@/api/api";

export default {
  data: () => ({
    rules: {
      counter: value => value.length <= 50 || 'Максимум 50 символов',
    },
  }),
  props: ['playlists', 'playingEntities', 'isOperator'],
  components: {},
  computed: {
    isDevMode() {
      return this.$store.state.dev_mode;
    },
    isPauseOn() {
      return this.$store.state.pause_button
    },
  },
  methods: {
    startPlaylist(playlist) {
      this.$store.state.start_button = true

      this.$store.dispatch('startPlaylist', playlist)
    },
    stopPlaylist(playlist) {
      this.$store.state.start_button = false
      this.$store.dispatch('stopPlaylist', playlist)
    },
    isPlayOn(playlist) {
      if (this.playingEntities.length == 0) {
        return false
      }
      else if (playlist.id == this.playingEntities[0].its_id && this.playingEntities[0].is_paused) {return false}
      else if (this.playingEntities[0].is_paused) {return true}
      else if (playlist.id == this.playingEntities[0].its_id && this.playingEntities[0].is_playing) {
        return true
      }
      return false
    },
    isStoped(playlist) {
      if (this.playingEntities.length == 0) {
        return true
      }
      else if (playlist.id == this.playingEntities[0].its_id && this.playingEntities[0].is_paused) {return true}
      else if (playlist.id == this.playingEntities[0].its_id && this.playingEntities[0].is_playing) {
        return false
      }
      return true
    },
    setButtonStatus(playlist) {
      playlist.play_button = true;
      playlist.stop_button = false;
      playlist.pause_button = false;
    },
    enablePlayButton(playlist) {
      playlist.play_button = false
      playlist.stop_button = true
      playlist.pause_button = true
    },
    enableStopButton(playlist) {
      playlist.play_button = true
      playlist.stop_button = false
      playlist.pause_button = true
    },
    enablePauseButton(playlist) {
      playlist.play_button = true
      playlist.stop_button = true
      playlist.pause_button = false
    },
    play(entity, entity_name) {
      Api.play(entity_name, entity.id).then(
        () => this.$store.commit('setEntities'),
      )
      // this.enablePlayButton(entity),
    },
    stop(entity, entity_name) {
      Api.stop(entity_name, entity.id).then(
        this.$store.commit('setEntities'),
        // this.$store.commit('enableStopButton'),
      )
    },
    pause(entity, entity_name) {
      Api.pause(entity_name, entity.id).then(
        this.$store.commit('setEntities'),
        // this.$store.commit('enablePauseButton'),
      )
    },
  },
};
</script>

<style>
</style>