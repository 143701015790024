<template>
  <div>
      <p class="title text-center">{{ $t('manmode_page.title') }}</p>
      <playlistPlayer :playlists="playlists" :playingEntities="playingEntities" :isOperator="isOperator" />
      <v-spacer ></v-spacer>
      <v-divider class="my-10"/>
      <cuePlayer :cues="cues" :playingEntities="playingEntities" :isOperator="isOperator"/>
  </div>
</template>

<script>
import playlistPlayer from '@/components/PlaylistPlayer'
import cuePlayer from '@/components/CuesPlayer'

export default {
  components: {playlistPlayer, cuePlayer},
  mounted() {
    this.$store.dispatch('getCues')
    this.$store.dispatch('getPlaylists')
    this.$store.commit('setEntities')
  },
  computed: {
    isOperator() {
      return this.$store.getters.isOperator
    },
    cues() {
      return this.$store.state.cues
    },
    playlists() {
      return this.$store.state.playlists
    },
    playingEntities() {
      return this.$store.state.playingEntities
    },
    isDevMode() {
      return this.$store.state.dev_mode
    }
  },
}
</script>
