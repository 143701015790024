<template>
  <div align="center">
    <h1>{{ $t('cues_player.title') }}</h1>
    <v-card
      width="80%"
      v-for="(cue,i) in cuesFiltered" :key="i"
      class="mt-2"
      flat
      >
        <v-toolbar color="white" height="220">
          <v-col align="left"  >
            <v-img v-if="cue.gif" width="200" height="200"
              :src='getGif(cue)' @click='resetGif(cue)'>
            </v-img>
            <v-img v-if="!cue.gif" max-width="200" max-height="200" style="opacity:0.1"
              :src="require('@/assets/gifs/stat.gif')">
            </v-img>
          </v-col>
          <v-col align="left" cols="6">
            <v-row>
              <v-col>{{cue.filename}}</v-col>
            </v-row>
            <v-divider/>
            <v-row>
              <v-col>{{ $t('cues_player.duration') }}: {{cue.duration}}</v-col>
              <v-col>{{ $t('cues_player.universes') }}: {{cue.uni_count}}</v-col>
              <v-col>{{ $t('cues_player.frames') }}: {{cue.frame_count}}</v-col>
            </v-row>
          </v-col>
          <v-col class="ml-auto" align="right" cols="3">
            <v-btn :disabled="isPlayOn(cue, 'Cue') || !isOperator" color="primary--text" elevation="0" @click="play(cue, 'Cue')"><v-icon>mdi-play</v-icon></v-btn>
            <v-btn :disabled="isStoped(cue, 'Cue') || !isOperator" color="primary--text" elevation="0" @click="pause(cue, 'Cue')"><v-icon>mdi-pause</v-icon></v-btn>
            <v-btn :disabled="isStoped(cue, 'Cue') || !isOperator" color="primary--text" elevation="0" @click="stop(cue, 'Cue')"><v-icon>mdi-stop</v-icon></v-btn>
          </v-col>
        </v-toolbar>
      <v-divider/>
    </v-card>
  </div>
</template>

<script>
import Api from "@/api/api";

export default {
  data: () => ({
    weekdays: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
    rules: {
      counter: value => value.length <= 50 || 'Максимум 50 символов',
    },
  }),
  props: ['cues', 'playingEntities', 'isOperator'],
  components: {},
  computed: {
    cuesFiltered() {
      return this.cues.filter((cue) => !cue.no_file)
    },
    isDevMode() {
      return this.$store.state.dev_mode;
    },
    isPauseOn() {
      return this.$store.state.pause_button
    },
  },
  methods: {
    getGif(cue) {
      return `${this.$store.state.mediaPath}${cue.gif}`
    },
    resetGif(cue) {
      let gif = cue.gif
      cue.gif = ""
      cue.gif = `${gif}?a=${Math.random()}`
    },
    isPlayOn(entity) {
      if (this.playingEntities.length == 0) {return false}
      else if (entity.id == this.playingEntities[0].its_id && this.playingEntities[0].is_paused) {return false}
      else if (this.playingEntities[0].is_paused) {return true}
      else if (entity.id == this.playingEntities[0].its_id && this.playingEntities[0].is_playing) {
        return true
      }
      return false
    },
    isStoped(entity) {
      if (this.playingEntities.length == 0) {
        return true
      }
      else if (entity.id == this.playingEntities[0].its_id && this.playingEntities[0].is_paused) {return true}
      else if (entity.id == this.playingEntities[0].its_id && this.playingEntities[0].is_playing) {
        return false
      }
      return true
    },
    play(entity, entity_name) {
      Api.play(entity_name, entity.id).then(
        () => this.$store.commit('setEntities'),
      )
      // this.enablePlayButton(entity),
    },
    stop(entity, entity_name) {
      Api.stop(entity_name, entity.id).then(
        this.$store.commit('setEntities'),
        // this.$store.commit('enableStopButton'),
      )
    },
    pause(entity, entity_name) {
      Api.pause(entity_name, entity.id).then(
        this.$store.commit('setEntities'),
        // this.$store.commit('enablePauseButton'),
      )
    },
  },
};
</script>

<style>
</style>